const doctrines = [
    {
      id: 'knowledge_of_god',
      title: "Knowledge of God",
      sections: [
        {
          id: 'ps19_1',
          displaytitle: 'General Revelation',
          displaydescription: "God is known generally, by all, through nature (the things that have been made), this being 'mediate revelation':",
          title: 'Psalm 19:1',
          content: 'The heavens declare the glory of God, and the sky above proclaims his handiwork.'
        },
        {
            id: 'rom1_18',
            title: 'Romans 1:19-20',
            content: 'For what can be known about God is plain to them, because God has shown it to them. 20For his invisible attributes, namely, his eternal power and divine nature, have been clearly perceived, ever since the creation of the world, in the things that have been made. So they are without excuse.'
        },
        {
          id: 'rom2_15',
          displaydescription: "God is known generally, by all, through our own conscience and heart, this being 'immediate revelation':",
          title: 'Romans 2:15',
          content: 'They show that the work of the law is written on their hearts, while their conscience also bears witness, and their conflicting thoughts accuse or even excuse them'
        }
      ]
    },
    {
      id: 'attributes_of_god',
      title: "Attributes of God",
      sections: [
        {
          id: 'god_is_one',
          displaytitle: 'God is One in Essence',
          title: 'Deuteronomy 6:4',
          content: '“Hear, O Israel: The LORD our God, the LORD is one.'
        },
        {
          id: 'gen26_24',
          displaytitle: 'God is Three in Person',
          displaydescription: "The Father is God:",
          title: 'Genesis 26:24',
          content: 'And the LORD appeared to him the same night and said, “I am the God of Abraham your father. Fear not, for I am with you and will bless you and multiply your offspring for my servant Abraham’s sake.”'
        },
        {
          id: 'luke11_13',
          displaydescription: "God the Father is a distinct person of the Godhead (meaning that God the Father is not God the Son or God the Spirit) and he plays a distinct role in the plan of redemption:",
          title: 'Luke 11:13',
          content: 'If you then, who are evil, know how to give good gifts to your children, how much more will the heavenly Father give the Holy Spirit to those who ask him!”'
        },
        {
            id: 'john_14_6',
            title: 'John 14:6',
            content: 'Jesus said to him, “I am the way, and the truth, and the life. No one comes to the Father except through me.'
        },
        {
          id: 'john_14_6',
          title: 'John 6:44',
          content: 'No one can come to me unless the Father who sent me draws him. And I will raise him up on the last day.'
        },
        {
          id: 'john8_58',
          displaydescription: "The Son is God:",
          title: 'John 8:58',
          content: 'Jesus said to them, “Truly, truly, I say to you, before Abraham was, I am.”'
        },
      ]
    },
    {
      id: 'prayer',
      title: "Prayer",
      sections: [
        {
          id: 'james4_3',
          displaydescription: "We should ask for that which we know God will be pleased and willing to give us. (A repentant heart, a steadfast faith, an obedience to Him):",
          title: 'James 4:3',
          content: 'You ask and do not receive, because you ask wrongly, to spend it on your passions.'
        },
        {
          id: '1john5_14',
          title: '1 John 5:14',
          content: 'And this is the confidence that we have toward him, that if we ask anything according to his will he hears us.'
        },
        {
          id: '1peter_3_12',
          displaytitle: 'Power/Effectiveness of Prayer',
          displaydescription: "Prayer is more effective for those who keep God's commandments:",
          title: '1 Peter 3:12',
          content: `For the eyes of the Lord are on the righteous,
and his ears are open to their prayer.
But the face of the Lord is against those who do evil.”`
        },
        {
          id: '1peter_3_7',
          title: '1 Peter 3:7',
          content: 'Likewise, husbands, live with your wives in an understanding way, showing honor to the woman as the weaker vessel, since they are heirs with you of the grace of life, **so that your prayers may not be hindered.**'
        },
        {
          id: '1john3_22',
          title: '1 John 3:22',
          content: 'and whatever we ask we receive from him, because we keep his commandments and do what pleases him.'
        },
        
      ]
    },
  ];
  
  export default doctrines;
  