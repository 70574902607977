import React, { useState, useEffect, useContext } from 'react';
import { firestore } from './firebase-config'; // Assuming firestore is configured
import { AuthContext } from './AuthContext'; // Import AuthContext for user info
import { doc, updateDoc, getDoc } from 'firebase/firestore'; // Import getDoc to fetch highscore
import verses from './verses.json'; // Import the verses JSON file

const MemoryGame = () => {
  const { loggedInUser, updateHighscore } = useContext(AuthContext); // Get logged-in user and updateHighscore function
  const [currentVerse, setCurrentVerse] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [feedback, setFeedback] = useState('');
  const [displayedPart, setDisplayedPart] = useState('title');
  const [streak, setStreak] = useState(0); // Current streak
  const [highscore, setHighscore] = useState(0); // Highscore for the session

  // Fetch highscore from Firestore when the user logs in
  useEffect(() => {
    if (loggedInUser?.id) {
      const fetchHighscore = async () => {
        try {
          const userDocRef = doc(firestore, 'users', loggedInUser.id);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setHighscore(userDoc.data().highscore || 0);
          } else {
            console.warn('User document does not exist in Firestore');
          }
        } catch (error) {
          console.error('Error fetching highscore:', error);
        }
      };
      fetchHighscore();
    }
  }, [loggedInUser]);

  const getRandomVerse = () => {
    if (!verses?.Verses || !Array.isArray(verses.Verses)) {
      console.error('Invalid verses data', verses);
      return;
    }
  
    const randomIndex = Math.floor(Math.random() * verses.Verses.length);
    const verse = verses.Verses[randomIndex];
  
    if (!verse?.title || !verse?.content) {
      console.error('Verse is missing required properties:', verse);
      return;
    }
  
    setDisplayedPart('content');
    setCurrentVerse(verse);
    setUserInput('');
    setFeedback('');
  };

  const removePunctuation = (text) => {
    return text.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ").trim();
  };

  const handleSubmit = () => {
    const cleanedUserInput = removePunctuation(userInput.trim().toLowerCase());
    const correctAnswer = removePunctuation(currentVerse[displayedPart === 'title' ? 'content' : 'title'].trim().toLowerCase());

    if (cleanedUserInput === correctAnswer) {
      setFeedback(`Correct! ${currentVerse[displayedPart === 'title' ? 'content' : 'title']}`);
      setStreak(streak + 1); // Increment streak
      // Update highscore if the current streak is higher than the previous highscore
      if (streak + 1 > highscore) {
        setHighscore(streak + 1);
        updateHighscoreInFirestore(streak + 1); // Save the new highscore to Firestore
      }
      setTimeout(getRandomVerse, 2000);
    } else {
      setFeedback(`Incorrect :( The correct answer is: ${currentVerse[displayedPart === 'title' ? 'content' : 'title']}`);
      setStreak(0); // Reset streak
      setTimeout(getRandomVerse, 5000);
    }
  };

  const updateHighscoreInFirestore = async (newHighscore) => {
    if (!loggedInUser) return; // Don't update if no user is logged in
    try {
      const userDocRef = doc(firestore, 'users', loggedInUser.id); // Use the logged-in user's ID to reference their document
      await updateDoc(userDocRef, { highscore: newHighscore });
      updateHighscore(newHighscore); // Update the context state
    } catch (error) {
      console.error("Error updating highscore: ", error);
    }
  };

  return (
    <div className="memory-game">
      <h2>Memory Game</h2>
      <p>Current Streak: {streak}</p> {/* Display current streak */}
      <p>Highscore: {highscore}</p> {/* Display highscore */}

      {currentVerse ? (
        <>
          <p>{currentVerse[displayedPart]}</p>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder={`Enter the ${displayedPart === 'title' ? 'content' : 'title'}`}
          />
          <button onClick={handleSubmit}>Submit</button>
          <p>{feedback}</p>
        </>
      ) : (
        <button onClick={getRandomVerse}>Start Game</button>
      )}
    </div>
  );
};

export default MemoryGame;
