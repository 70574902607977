import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import CharacterStudyPage from './CharacterStudy.js';
import notes from './BibleNotes.js'; // Import notes data
import doctrines from './Doctrines.js'; // Import doctrines data
import barnabas from './bible_character_studies/barnabas.js'; // Import barnabas character data
import testCharacter from './bible_character_studies/test_character.js'; // Import testCharacter character data
import renderContent from './renderContent.js';
import homePageContent from './homePageContent.js';
import CommunityPage from './CommunityPage.js';
import InstitutesTracker from './InstitutesTracker.js';
import BookTracker from './BookTracker.js';
import MemoryGame from './MemoryGame';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import bcrypt from 'bcryptjs';
import { firestore } from './firebase-config';
import { AuthContext, AuthProvider } from './AuthContext'; // Import AuthContext
let globalUsername = 'Guest';
function HomePage() {
  const { loggedInUser, loginUser, logoutUser } = useContext(AuthContext); // Access AuthContext
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  // Handle sign-up logic
  const handleSignUp = async () => {
    // Regular expression for valid username (only alphanumeric and underscores)
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
  
    // Regular expression for valid email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    // List of offensive words (this can be expanded)
    const offensiveWords = ['shit', 'fuck', 'dick', 'nigger', 'cunt', 'bitch'];
  
    // Check if username contains any offensive words
    const containsOffensiveWords = offensiveWords.some(word => username.toLowerCase().includes(word.toLowerCase()));
  
    if (containsOffensiveWords) {
      setMessage('Username contains offensive language. Please choose a different one.');
      return;
    }
  
    if (!usernameRegex.test(username)) {
      setMessage('Username can only contain letters, numbers, and underscores.');
      return;
    }
  
    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email address.');
      return;
    }
  
    try {
      // Check if username already exists
      const usernameQuery = query(collection(firestore, 'users'), where('username', '==', username));
      const usernameSnapshot = await getDocs(usernameQuery);
  
      if (!usernameSnapshot.empty) {
        setMessage('Username already exists. Please choose a different one.');
        return;
      }
  
      // Check if email already exists
      const emailQuery = query(collection(firestore, 'users'), where('email', '==', email));
      const emailSnapshot = await getDocs(emailQuery);
  
      if (!emailSnapshot.empty) {
        setMessage('Email is already registered. Please use a different email.');
        return;
      }
  
      // Hash the password
      const hashedPassword = await bcrypt.hash(password, 10);
  
      // Create a reference to the document with the username as the ID
      const docRef = doc(firestore, 'users', username);
  
      // Set the document data
      await setDoc(docRef, {
        username,
        email,
        password: hashedPassword,
        buttonScore: 0,
        rank: 'User',
      });
  
      setMessage('Sign-up successful! You can now log in.');
      setIsLogin(true); // Switch to login mode
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Handle login logic
  const handleLogin = async () => {
    try {
      const q = query(collection(firestore, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setMessage('User not found.');
        return;
      }
  
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();
      const isPasswordValid = await bcrypt.compare(password, userData.password);
  
      if (isPasswordValid) {
        loginUser({ 
          username: userData.username, 
          email: userData.email, 
          rank: userData.rank // Include rank in context 
        });
        globalUsername = userData.username; // Update the global variable
        localStorage.setItem('globalUsername', userData.username); // Save to localStorage
  
        setUsername(userData.username); // Set username state
        setMessage(`Welcome back, ${userData.username}!`);
      } else {
        setMessage('Invalid password.');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };
  

  // Handle logout
  const handleLogout = () => {
    logoutUser(); // Clear AuthContext
    localStorage.removeItem('globalUsername'); // Remove from localStorage
    globalUsername = 'Guest'; // Reset global variable
    setUsername('Guest'); // Reset username state
    setMessage('Logged out successfully.');
  };


  return (
    <div className="home-page">
      <h2>{renderContent(homePageContent.title)}</h2>
      <p>{renderContent(homePageContent.description)}</p>
      <p>{renderContent(homePageContent.note)}</p>
    
      {/* <h1>Firebase Data (TEST)</h1>
           <input
               type="text"
               value={newItem}
               onChange={(e) => setNewItem(e.target.value)}
               placeholder="Add a new item"
           />
           <button onClick={addData}>Add</button>
           <ul>
               {data.map(item => (
                   <li key={item.id}>{item.name}</li>
               ))}
           </ul> */}
           
<div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
        <h1>{isLogin ? 'Login (BETA)' : 'Sign Up (BETA)'}</h1>

        {loggedInUser ? (
          <div>
            <p>
              <strong>Welcome, {loggedInUser.username}!</strong>
            </p>
            <button onClick={handleLogout} style={{ width: '100%', marginBottom: '10px' }}>
              Log Out
            </button>
          </div>
        ) : (
          <div>
            {!isLogin && (
              <div>
                <label>Username:</label>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  style={{ display: 'block', marginBottom: '10px', width: '100%' }}
                />
              </div>
            )}

            <div>
              <label>Email:</label>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ display: 'block', marginBottom: '10px', width: '100%' }}
              />
            </div>

            <div>
              <label>Password:</label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ display: 'block', marginBottom: '20px', width: '100%' }}
              />
            </div>

            {isLogin ? (
              <button onClick={handleLogin} style={{ marginBottom: '10px', width: '100%' }}>
                Log In
              </button>
            ) : (
              <button onClick={handleSignUp} style={{ marginBottom: '10px', width: '100%' }}>
                Sign Up
              </button>
            )}

            <button
              onClick={() => setIsLogin(!isLogin)}
              style={{ display: 'block', marginTop: '10px', width: '100%' }}
            >
              {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
            </button>
          </div>
        )}

        {message && <p style={{ color: isLogin ? 'green' : 'blue', marginTop: '20px' }}>{message}</p>}
      </div>
    </div>
  );
}

const scrollToLowerPage = () => {
  const element = document.getElementById('lower-page');
  element.scrollIntoView({ behavior: 'smooth' });
};

function App() {
  const { loggedInUser } = useContext(AuthContext); // Access loggedInUser
  const [currentPage, setCurrentPage] = useState('home');
  const [isCharacterDropdownOpen, setIsCharacterDropdownOpen] = useState(false);
  const [isNotesDropdownOpen, setIsNotesDropdownOpen] = useState(false);
  const [isDoctrinesDropdownOpen, setIsDoctrinesDropdownOpen] = useState(false); // Doctrines dropdown state
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedDoctrine, setSelectedDoctrine] = useState(null); // Doctrine selection
  const [characters] = useState([barnabas, testCharacter]); // Updated characters array
  const [darkMode, setDarkMode] = useState(true); // State for dark mode
  const [notePassword, setNotePassword] = useState('');
  const [isPasswordPromptVisible, setIsPasswordPromptVisible] = useState(false);
  const [passwordProtectedNote, setPasswordProtectedNote] = useState(null);

  const toggleCharacterDropdown = () => {
    setIsCharacterDropdownOpen(!isCharacterDropdownOpen);
    if (!isCharacterDropdownOpen) {
      setIsNotesDropdownOpen(false);
      setIsDoctrinesDropdownOpen(false); // Close doctrines dropdown if opened
    }
  };

  const toggleNotesDropdown = () => {
    setIsNotesDropdownOpen(!isNotesDropdownOpen);
    if (!isNotesDropdownOpen) {
      setIsCharacterDropdownOpen(false);
      setIsDoctrinesDropdownOpen(false); // Close doctrines dropdown if opened
    }
  };

  const toggleDoctrinesDropdown = () => {
    setIsDoctrinesDropdownOpen(!isDoctrinesDropdownOpen);
    if (!isDoctrinesDropdownOpen) {
      setIsCharacterDropdownOpen(false);
      setIsNotesDropdownOpen(false); // Close notes dropdown if opened
    }
  };

  const selectCharacter = (character) => {
    setSelectedCharacter(character);
    setCurrentPage('characterStudy');
    setIsCharacterDropdownOpen(false); // Close the character dropdown after selecting a character
    setTimeout(scrollToLowerPage, 20);
  };

  const handlePasswordSubmit = () => {
    if (passwordProtectedNote.password === notePassword) {
      setSelectedNote(passwordProtectedNote);
      setIsNotesDropdownOpen(false);
      setCurrentPage('noteContent');
      setTimeout(scrollToLowerPage, 20);
      setIsPasswordPromptVisible(false);
      setNotePassword('');
      setPasswordProtectedNote(null);
    } else {
      alert('Incorrect password!');
    }
  };

  const handlePasswordCancel = () => {
    setIsPasswordPromptVisible(false);
    setNotePassword('');
    setPasswordProtectedNote(null);
  };

  const selectNote = (note) => {
    if (note.password) {
      setPasswordProtectedNote(note);
      setIsPasswordPromptVisible(true);
      setIsNotesDropdownOpen(false);
    } else {
      setSelectedNote(note);
      setIsNotesDropdownOpen(false); // Close the notes dropdown after selecting a note
      setCurrentPage('noteContent');
      setTimeout(scrollToLowerPage, 20);
    }
  };

  const selectDoctrine = (doctrine) => {
    setSelectedDoctrine(doctrine);
    setIsDoctrinesDropdownOpen(false); // Close the doctrines dropdown after selecting a doctrine
    setCurrentPage('doctrineContent');
    setTimeout(scrollToLowerPage, 20);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <AuthProvider>
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <header className="App-header">
        <h1>Saved Clay - Bible Notes</h1>
        <nav className="navigation">
          <button onClick={() => setCurrentPage('community')}>Community</button>
          <button onClick={() => setCurrentPage('home')}>Home</button>
          <button onClick={() => setCurrentPage('institutesTracker')}>Institutes Tracker</button>
          <button onClick={() => setCurrentPage('BookTracker')}>Book Tracker</button>

          
          {/* Notes Dropdown (Visible Only for Aaron) */}
          <div className="dropdown">
            {loggedInUser?.username === "Aaron" && (
              <>
                <button className="dropdown-button" onClick={toggleNotesDropdown}>
                  Notes {isNotesDropdownOpen ? '▲' : '▼'}
                </button>
                <button className="dropdown-button" onClick={toggleDoctrinesDropdown}>
                  Doctrines {isDoctrinesDropdownOpen ? '▲' : '▼'}
                </button>
                <div className={`dropdown-content ${isNotesDropdownOpen ? 'show' : ''}`}>
                  {notes.map((note) => (
                    <div key={note.id} className="dropdown-item" onClick={() => selectNote(note)}>
                      {note.title}
                    </div>
                  ))}
                </div>
                <div className={`dropdown-content ${isDoctrinesDropdownOpen ? 'show' : ''}`}>
                  {doctrines.map((doctrine) => (
                    <div key={doctrine.id} className="dropdown-item" onClick={() => selectNote(doctrine)}>
                      {doctrine.title}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <button onClick={() => setCurrentPage('memoryGame')}>Memory Game</button>
        </nav>
      </header>
      <div className="container" id="lower-page">
        {currentPage === 'home' ? (
          <HomePage />
        ) : currentPage === 'community' ? (
          <CommunityPage />
        ) :currentPage === 'institutesTracker' ? (
          <InstitutesTracker username={loggedInUser?.username || 'Guest'} />
        ) :currentPage === 'BookTracker' ? (
          <BookTracker username={loggedInUser?.username || 'Guest'} />
        ) : currentPage === 'characterStudy' ? (
          <CharacterStudyPage character={selectedCharacter} />
        ) : currentPage === 'noteContent' ? (
          <div className="note-content-page">
            <h2>{selectedNote.title}</h2>
            {selectedNote.title.includes("Greek") ? (
              shuffleArray(selectedNote.sections).map((section) => (
                <NoteSection key={`${selectedNote.id}-${section.id}`} displaytitle={section.displaytitle} displaydescription={section.displaydescription} title={section.title} content={section.content} />
              ))
            ) : (
              selectedNote.sections.map((section) => (
                <NoteSection key={`${selectedNote.id}-${section.id}`} displaytitle={section.displaytitle} displaydescription={section.displaydescription} title={section.title} content={section.content} />
              ))
            )}
          </div>
        ) : currentPage === 'doctrineContent' ? (
          <div className="doctrine-content-page">
            <h2>{selectedDoctrine.title}</h2>
            {selectedDoctrine.sections.map((section) => (
              <NoteSection key={`${selectedDoctrine.id}-${section.id}`} displaytitle={section.displaytitle} displaydescription={section.displaydescription} title={section.title} content={section.content} />
            ))}
          </div>
        ) : currentPage === 'memoryGame' ? (
          <MemoryGame />
        ) : null}
      </div>

      {isPasswordPromptVisible && (
        <div className="password-prompt-overlay">
          <div className="password-prompt">
            <h2>Enter Password</h2>
            <input
              type="password"
              value={notePassword}
              onChange={(e) => setNotePassword(e.target.value)}
            />
            <button onClick={handlePasswordSubmit}>Submit</button>
            <button onClick={handlePasswordCancel}>Cancel</button>
          </div>
        </div>
      )}
    </div>
    </AuthProvider>
  );
}
const NoteSection = ({ displaytitle, displaydescription, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="note-section">
      {displaytitle && <h3 className="note-display-title">{displaytitle}</h3>}
      {displaydescription && <p className="note-display-description">{displaydescription}</p>}
      <div className="note-section-title" onClick={toggleSection}>
        {title}
      </div>
      {isOpen && (
        <div className="note-section-content active">
          {renderContent(content)}
        </div>
      )}
    </div>
  );
};

// Function to shuffle array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default App;
