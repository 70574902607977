// src/InstitutesTracker.js
import React, { useState, useEffect, useContext } from 'react';
import { firestore } from './firebase-config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { AuthContext, AuthProvider } from './AuthContext'; // Import AuthContext
import './ReadingPlanner.css';

const institutesStructure = [
  { book: 'Book 1', chapters: 18 },
  { book: 'Book 2', chapters: 17 },
  { book: 'Book 3', chapters: 25 },
  { book: 'Book 4', chapters: 20 },
];

const InstitutesTracker = () => {
  const { loggedInUser } = useContext(AuthContext); // Access AuthContext
  const [selectedBook, setSelectedBook] = useState('');
  const [selectedChapter, setSelectedChapter] = useState(0);
  const [customDate, setCustomDate] = useState('2025-12-31'); // Default date
  const [chaptersPerDay, setChaptersPerDay] = useState(null);
  const [chaptersPerWeek, setChaptersPerWeek] = useState(null);
  const [chaptersPerMonth, setChaptersPerMonth] = useState(null);

  // Load saved progress from Firestore when the component mounts
  useEffect(() => {
    const loadSavedProgress = async () => {
      const userId = loggedInUser.username; // You can replace this with dynamic user IDs if needed
      const docRef = doc(firestore, 'readingPlansInstitutes', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setSelectedBook(data.selectedBook);
        setSelectedChapter(data.selectedChapter);
        setCustomDate(data.customDate);
      }
    };
    loadSavedProgress();
  }, []);

  // Save progress to Firestore
  const saveProgress = async () => {
    const userId = loggedInUser.username; // You can replace this with dynamic user IDs if needed
    const docRef = doc(firestore, 'readingPlansInstitutes', userId);
    try {
      await setDoc(docRef, {
        selectedBook,
        selectedChapter,
        customDate,
      });
      alert('Progress saved successfully!');
    } catch (error) {
      console.error('Error saving progress:', error);
      alert('Failed to save progress.');
    }
  };

  // Calculate days until the custom deadline
  const calculateDaysUntilDeadline = () => {
    const today = new Date();
    const deadline = new Date(customDate);
    const timeDifference = deadline - today; // Time difference in milliseconds
    return Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert to days
  };

  const calculateChapters = () => {
    // Calculate total chapters remaining
    let totalChapters = 0;
    let started = false;

    for (const book of institutesStructure) {
      if (book.book === selectedBook) {
        started = true;
        totalChapters += book.chapters - selectedChapter + 1;
      } else if (started) {
        totalChapters += book.chapters;
      }
    }

    const remainingDays = calculateDaysUntilDeadline();
    
    // Calculate chapters per day, week, and month
    const chaptersPerDay = (totalChapters / remainingDays).toFixed(2);
    const chaptersPerWeek = (totalChapters / (remainingDays / 7)).toFixed(2);
    const chaptersPerMonth = (totalChapters / (remainingDays / 30)).toFixed(2);

    setChaptersPerDay(chaptersPerDay);
    setChaptersPerWeek(chaptersPerWeek);
    setChaptersPerMonth(chaptersPerMonth);
  };

  return (

    <div className="institutes-tracker" style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h1>Institutes Reading Planner</h1>
      <p>Select your current position and get a daily, weekly, and monthly reading plan to finish by your selected date.</p>

      <div>
        <label>Current Book:</label>
        <select
          value={selectedBook}
          onChange={(e) => setSelectedBook(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', width: '100%' }}
        >
          <option value="">Select a Book</option>
          {institutesStructure.map((book) => (
            <option key={book.book} value={book.book}>
              {book.book}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Current Chapter:</label>
        <input
          type="number"
          value={selectedChapter}
          onChange={(e) => setSelectedChapter(Number(e.target.value))}
          style={{ display: 'block', marginBottom: '10px', width: '100%' }}
          disabled={!selectedBook}
          min="1"
          max={selectedBook ? institutesStructure.find((book) => book.book === selectedBook)?.chapters || 1 : 1}
        />
      </div>

      <div>
        <label>Finish By (Date):</label>
        <input
          type="date"
          value={customDate}
          onChange={(e) => setCustomDate(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', width: '100%' }}
        />
      </div>

      <button
        onClick={calculateChapters}
        style={{ marginBottom: '10px', width: '100%' }}
        disabled={!selectedBook || !selectedChapter}
      >
        Calculate Plan
      </button>

      <button
        onClick={saveProgress}
        style={{ marginBottom: '10px', width: '100%' }}
        disabled={!selectedBook || !selectedChapter}
      >
        Save Progress
      </button>

      {chaptersPerDay && (
        <div>
          <h3>Reading Plan:</h3>
          <p>To complete the whole of Calvin's Institutes, by <b>{customDate}</b></p>
          <p>
            Read approximately <b>{chaptersPerDay}</b> chapters per day.
          </p>
          <p>
            Read approximately <b>{chaptersPerWeek}</b> chapters per week.
          </p>
          <p>
            Read approximately <b>{chaptersPerMonth}</b> chapters per month.
          </p>
        </div>
      )}
    </div>
  );
};

export default InstitutesTracker;
