import React, { useState, useEffect, useContext } from 'react';
import { firestore } from './firebase-config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { AuthContext } from './AuthContext'; // Assuming you're using AuthContext for user authentication
import './BookReadingPlanner.css';

const BookTracker = () => {
  const { loggedInUser } = useContext(AuthContext); // Access logged-in user from context
  const [bookTitle, setBookTitle] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [finishDate, setFinishDate] = useState('2025-12-31'); // Default date
  const [pagesPerDay, setPagesPerDay] = useState(null);
  const [pagesPerWeek, setPagesPerWeek] = useState(null);
  const [pagesPerMonth, setPagesPerMonth] = useState(null);

  // Load saved progress from Firestore when the component mounts
  useEffect(() => {
    const loadSavedProgress = async () => {
      const userId = loggedInUser.username; // Replace with dynamic user IDs if necessary
      const docRef = doc(firestore, 'readingPlans', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setBookTitle(data.bookTitle);
        setTotalPages(data.totalPages);
        setCurrentPage(data.currentPage);
        setFinishDate(data.finishDate);
      }
    };
    loadSavedProgress();
  }, [loggedInUser]);

  // Save progress to Firestore
  const saveProgress = async () => {
    const userId = loggedInUser.username;
    const docRef = doc(firestore, 'readingPlans', userId);
    try {
      await setDoc(docRef, {
        bookTitle,
        totalPages,
        currentPage,
        finishDate,
      });
      alert('Progress saved successfully!');
    } catch (error) {
      console.error('Error saving progress:', error);
      alert('Failed to save progress.');
    }
  };

  // Calculate days until the custom deadline
  const calculateDaysUntilDeadline = () => {
    const today = new Date();
    const deadline = new Date(finishDate);
    const timeDifference = deadline - today; // Time difference in milliseconds
    return Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert to days
  };

  const calculateReadingPlan = () => {
    const remainingPages = totalPages - currentPage;
    const remainingDays = calculateDaysUntilDeadline();
    
    // Calculate pages per day, week, and month
    const pagesPerDay = (remainingPages / remainingDays).toFixed(2);
    const pagesPerWeek = (remainingPages / (remainingDays / 7)).toFixed(2);
    const pagesPerMonth = (remainingPages / (remainingDays / 30)).toFixed(2);

    setPagesPerDay(pagesPerDay);
    setPagesPerWeek(pagesPerWeek);
    setPagesPerMonth(pagesPerMonth);
  };

  return (
    <div className="book-tracker" style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h1>Book Reading Tracker</h1>
      <p>Enter your book details and get a daily, weekly, and monthly reading plan to finish by your selected date.</p>

      <div>
        <label>Book Title:</label>
        <input
          type="text"
          value={bookTitle}
          onChange={(e) => setBookTitle(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', width: '100%' }}
        />
      </div>

      <div>
        <label>Total Pages:</label>
        <input
          type="number"
          value={totalPages}
          onChange={(e) => setTotalPages(Number(e.target.value))}
          style={{ display: 'block', marginBottom: '10px', width: '100%' }}
          min="1"
        />
      </div>

      <div>
        <label>Current Page:</label>
        <input
          type="number"
          value={currentPage}
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          style={{ display: 'block', marginBottom: '10px', width: '100%' }}
          min="0"
          max={totalPages}
        />
      </div>

      <div>
        <label>Finish By (Date):</label>
        <input
          type="date"
          value={finishDate}
          onChange={(e) => setFinishDate(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', width: '100%' }}
        />
      </div>

      <button
        onClick={calculateReadingPlan}
        style={{ marginBottom: '10px', width: '100%' }}
        disabled={!bookTitle || totalPages === 0 || currentPage === 0 || !finishDate}
      >
        Calculate Plan
      </button>

      <button
        onClick={saveProgress}
        style={{ marginBottom: '10px', width: '100%' }}
        disabled={!bookTitle || totalPages === 0 || currentPage === 0 || !finishDate}
      >
        Save Plan
      </button>

      {pagesPerDay && (
        <div>
          <h3>Reading Plan:</h3>
          <p>
            To finish by <b>{finishDate}</b>, read approximately <b>{pagesPerDay}</b> pages per day.
          </p>
          <p>
            To finish by <b>{finishDate}</b>, read approximately <b>{pagesPerWeek}</b> pages per week.
          </p>
          <p>
            To finish by <b>{finishDate}</b>, read approximately <b>{pagesPerMonth}</b> pages per month.
          </p>
        </div>
      )}
    </div>
  );
};

export default BookTracker;
