import React, { createContext, useState, useEffect } from 'react';
import { firestore } from './firebase-config'; // Import your firebase config
import { doc, getDoc } from 'firebase/firestore'; // Import necessary Firestore functions

// Create a Context for authentication
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [highscore, setHighscore] = useState(0); // Add state for highscore
    const [rank, setRank] = useState('User'); // Initial rank set to 'User'
    const [loading, setLoading] = useState(true); // Track loading state

    // Fetch rank from Firestore
    const fetchRank = async (userId) => {
        try {
            const userRef = doc(firestore, 'users', userId); // Assuming 'users' collection
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setRank(userData.rank || 'User'); // Default to 'User' if no rank is found
            } else {
                setRank('User'); // If no user data, default to 'User'
            }
        } catch (error) {
            console.error('Error fetching rank:', error);
            setRank('User'); // Fallback to 'User' in case of error
        } finally {
            setLoading(false); // Set loading to false once done fetching
        }
    };

    useEffect(() => {
        // Check if the user data and highscore are stored in localStorage
        const storedUser = localStorage.getItem('user');
        const storedHighscore = localStorage.getItem('highscore');

        if (storedUser) {
            const user = JSON.parse(storedUser);
            setLoggedInUser(user);
            fetchRank(user.email); // Assuming email as unique user identifier for fetching rank
        }

        if (storedHighscore) {
            setHighscore(parseInt(storedHighscore, 10)); // Ensure the highscore is a number
        }
    }, []);

    const loginUser = (userData) => {
        setLoggedInUser(userData);
        localStorage.setItem('user', JSON.stringify(userData)); // Store user data in localStorage
        fetchRank(userData.email); // Fetch rank on login
    };

    const logoutUser = () => {
        setLoggedInUser(null);
        setHighscore(0); // Reset highscore when logging out
        setRank('User'); // Reset rank on logout
        localStorage.removeItem('user'); // Remove user data from localStorage
        localStorage.removeItem('highscore'); // Remove highscore from localStorage
    };

    const updateHighscore = (newHighscore) => {
        if (newHighscore > highscore) { // Only update if the new score is higher
            setHighscore(newHighscore);
            localStorage.setItem('highscore', newHighscore); // Store new highscore in localStorage
        }
    };

    return (
        <AuthContext.Provider value={{ loggedInUser, loginUser, logoutUser, highscore, rank, updateHighscore, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
