import React, { useState, useContext, useEffect } from 'react';
import { firestore } from './firebase-config';
import {
  collection,
  addDoc,
  query,
  getDocs,
  orderBy,
  limit,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { AuthContext } from './AuthContext';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();
const MAX_CONTENT_LENGTH = 200;

function CommunityPage() {
  const { loggedInUser, rank, loading } = useContext(AuthContext);  // Use rank and loading from context
  const [threads, setThreads] = useState([]);
  const [newThread, setNewThread] = useState('');
  const [message, setMessage] = useState('');

  // Fetch threads
  const fetchThreads = async () => {
    try {
      const threadsRef = collection(firestore, 'threads');
      const q = query(threadsRef, orderBy('likes', 'desc'), limit(50));
      const querySnapshot = await getDocs(q);
      const threadsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setThreads(threadsData);
    } catch (error) {
      console.error('Error fetching threads:', error);
      setMessage('Error fetching threads. Please try again.');
    }
  };

  // Create a new thread
  const handleCreateThread = async () => {
    if (!newThread.trim()) {
      setMessage('Please enter a thread message.');
      return;
    }

    if (!loggedInUser) {
      setMessage('You must be logged in to create a thread. Log in from the /home/ page.');
      return;
    }

    try {
      const threadsRef = collection(firestore, 'threads');
      await addDoc(threadsRef, {
        content: newThread,
        createdAt: new Date(),
        username: loggedInUser.username,
        userId: loggedInUser.email,
        likes: 0,
        dislikes: 0,
        likedBy: [],
        dislikedBy: [],
      });

      setNewThread('');
      setMessage('Thread created successfully!');
      fetchThreads();
    } catch (error) {
      console.error('Error creating thread:', error);
      setMessage('Error creating thread. Please try again.');
    }
  };

  // Handle like/dislike actions
  const handleLikeDislike = async (threadId, action) => {
    if (!loggedInUser) {
      setMessage('You must be logged in to like or dislike a thread.');
      return;
    }

    try {
      const threadRef = doc(firestore, 'threads', threadId);
      const threadDoc = await getDoc(threadRef);

      if (threadDoc.exists()) {
        const data = threadDoc.data();
        const { likedBy, dislikedBy } = data;

        if (action === 'like') {
          if (likedBy.includes(loggedInUser.username)) {
            await updateDoc(threadRef, {
              likes: data.likes - 1,
              likedBy: likedBy.filter((user) => user !== loggedInUser.username),
            });
          } else {
            if (dislikedBy.includes(loggedInUser.username)) {
              await updateDoc(threadRef, {
                dislikes: data.dislikes - 1,
                dislikedBy: dislikedBy.filter((user) => user !== loggedInUser.username),
              });
            }
            await updateDoc(threadRef, {
              likes: data.likes + 1,
              likedBy: [...likedBy, loggedInUser.username],
            });
          }
        } else if (action === 'dislike') {
          if (dislikedBy.includes(loggedInUser.username)) {
            await updateDoc(threadRef, {
              dislikes: data.dislikes - 1,
              dislikedBy: dislikedBy.filter((user) => user !== loggedInUser.username),
            });
          } else {
            if (likedBy.includes(loggedInUser.username)) {
              await updateDoc(threadRef, {
                likes: data.likes - 1,
                likedBy: likedBy.filter((user) => user !== loggedInUser.username),
              });
            }
            await updateDoc(threadRef, {
              dislikes: data.dislikes + 1,
              dislikedBy: [...dislikedBy, loggedInUser.username],
            });
          }
        }

        fetchThreads();
      }
    } catch (error) {
      console.error('Error updating likes/dislikes:', error);
      setMessage('An error occurred while updating the thread.');
    }
  };

  // Delete thread (Admin only)
  const handleDeleteThread = async (threadId) => {
    if (window.confirm('Are you sure you want to delete this thread?')) {
      try {
        const threadRef = doc(firestore, 'threads', threadId);
        await deleteDoc(threadRef);
        setThreads(threads.filter((thread) => thread.id !== threadId));
        setMessage('Thread deleted successfully!');
      } catch (error) {
        console.error('Error deleting thread:', error);
        setMessage('Error deleting thread.');
      }
    }
  };

  // Toggle expanded content
  const toggleExpand = (threadId) => {
    setThreads((prevThreads) =>
      prevThreads.map((thread) =>
        thread.id === threadId
          ? { ...thread, isExpanded: !thread.isExpanded }
          : thread
      )
    );
  };

  useEffect(() => {
    fetchThreads();
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Show loading state while rank is being fetched
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ padding: '20px', maxWidth: '700px', margin: 'auto' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Community Page</h1>
        <p style={{ textAlign: 'center' }}>
          Welcome, {loggedInUser ? loggedInUser.username : 'Guest'} {rank && `(${rank})`}
        </p>

        <div style={{ marginBottom: '20px' }}>
          <h2>Create a Post</h2>
          <textarea
            placeholder="Write your thread here..."
            value={newThread}
            onChange={(e) => setNewThread(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              marginBottom: '10px',
              borderRadius: '5px',
              border: '1px solid #ddd',
            }}
          />
          <button
            onClick={handleCreateThread}
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Create Thread
          </button>
        </div>

        {message && (
          <p style={{ color: 'green', textAlign: 'center', marginBottom: '20px' }}>
            {message}
          </p>
        )}

        <h2>Posts</h2>
        <div style={{ maxHeight: '400px', overflowY: 'auto', paddingRight: '10px' }}>
          {threads.length > 0 ? (
            threads.map((thread) => (
              <div
                key={thread.id}
                style={{
                  textAlign: 'left',
                  marginBottom: '20px',
                  wordWrap: 'break-word',
                  borderRadius: '10px',
                  padding: '10px',
                  color: 'white',
                  border: '2px solid white',
                }}
              >
                <h3 style={{ margin: '0 0 10px 0', color: '#fff' }}>{thread.username}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: md.render(
                      thread.isExpanded
                        ? thread.content
                        : `${thread.content.slice(0, MAX_CONTENT_LENGTH)}`
                    ),
                  }}
                  style={{ marginBottom: '10px' }}
                />
                {thread.content.length > MAX_CONTENT_LENGTH && (
                  <button
                    onClick={() => toggleExpand(thread.id)}
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#007BFF',
                      cursor: 'pointer',
                    }}
                  >
                    {thread.isExpanded ? 'Show Less' : 'Show More'}
                  </button>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <div>
                    <button
                      onClick={() => handleLikeDislike(thread.id, 'like')}
                      style={{
                        backgroundColor:
                          loggedInUser && thread.likedBy.includes(loggedInUser.username)
                            ? '#4CAF50'
                            : '#f1f1f1',
                        color:
                          loggedInUser && thread.likedBy.includes(loggedInUser.username)
                            ? 'white'
                            : '#333',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        cursor: 'pointer',
                        marginRight: '10px',
                      }}
                    >
                      👍 {thread.likes}
                    </button>
                    <button
                      onClick={() => handleLikeDislike(thread.id, 'dislike')}
                      style={{
                        backgroundColor:
                          loggedInUser && thread.dislikedBy.includes(loggedInUser.username)
                            ? '#f44336'
                            : '#f1f1f1',
                        color:
                          loggedInUser && thread.dislikedBy.includes(loggedInUser.username)
                            ? 'white'
                            : '#333',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        cursor: 'pointer',
                      }}
                    >
                      👎 {thread.dislikes}
                    </button>
                  </div>
                  {rank === 'Admin' && (
                    <button
                      onClick={() => handleDeleteThread(thread.id)}
                      style={{
                        color: 'red',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <p style={{ marginTop: '10px', fontSize: '12px', color: '#fff' }}>
                  {new Date(thread.createdAt.seconds * 1000).toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <p>No threads available yet.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommunityPage;
